import React from 'react';
import { Link } from 'react-router-dom';

function SportFacilitySection() {
    return (
        <div className="container pad-30">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="card-column column-0">
                        <Link to="/sports-facilities">
                            <div
                                className="card card-color-0 wow fadeInUp animated"
                                data-wow-delay="0.2s"
                                style={{
                                    visibility: 'visible',
                                    animationDelay: '0.2s',
                                    animationName: 'fadeInUp',
                                }}
                            >
                                <div className="border"></div>
                                <img src="../../assets/images/fooball.jpg" alt="Football" title="Football" />
                                <h1> Football </h1>
                                <p>
                                    At Sanjay Sports Academy, training coaching starts from (timing) and the turnout of the students who train with the best of the staff is remarkable.
                                </p>
                            </div>
                        </Link>

                        <Link to="/sports-facilities">
                            <div
                                className="card card-color-2 wow fadeInUp animated"
                                data-wow-delay="0.6s"
                                style={{
                                    visibility: 'visible',
                                    animationDelay: '0.6s',
                                    animationName: 'fadeInUp',
                                }}
                            >
                                <div className="border"></div>
                                <img src="../../assets/images/cricket.jpg" alt="Cricket" title="Cricket" />
                                <h1> Cricket </h1>
                                <p>
                                    Sanjay Sports Academy is situated in Pashan, Pune, and offers facilities like four practice nets and a dedicated ground for cricket training and guidance.
                                </p>
                            </div>
                        </Link>
                    </div>

                    <div className="card-column column-1">
                        <Link to="/sports-facilities">
                            <div
                                className="card card-color-1 wow fadeInUp animated"
                                data-wow-delay="0.4s"
                                style={{
                                    visibility: 'visible',
                                    animationDelay: '0.4s',
                                    animationName: 'fadeInUp',
                                }}
                            >
                                <div className="border"></div>
                                <img src="../../assets/images/basketball.jpg" alt="Basketball" title="Basketball" />
                                <h1> Basketball </h1>
                                <p> A popular sport of the west, basketball is as famous as cricket and is played & enjoyed by people of almost all ages. </p>
                            </div>
                        </Link>

                        <Link to="/sports-facilities">
                            <div
                                className="card card-color-3 wow fadeInUp animated"
                                data-wow-delay="0.8s"
                                style={{
                                    visibility: 'visible',
                                    animationDelay: '0.8s',
                                    animationName: 'fadeInUp',
                                }}
                            >
                                <div className="border"></div>
                                <img src="../../assets/images/swimming.jpg" alt="Swimming" title="Swimming" />
                                <h1> Swimming </h1>
                                <p> Sanjay Sports Academy is the best swimming academy ever. </p>
                            </div>
                        </Link>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                        <Link to="/sports-facilities" className="effect effect1">
                            View All
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SportFacilitySection;
