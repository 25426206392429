import { useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Header from '../components/Header';
import Footer from '../components/Footer';



export default function MyGallery({ images }) {
    const [index, setIndex] = useState(-1);

    const handleClick = (index, item) => setIndex(index);
    const slides = images.map(({ src, width, height }) => ({
        src: src,
        width,
        height,
    }));
    return (
        <div>
            <Gallery
                images={images}
                onClick={handleClick}
                enableImageSelection={false}
            />
            <Lightbox
                slides={slides}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
            />
        </div>
    );
}
