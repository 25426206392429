import React from 'react';
import { Link } from 'react-router-dom';
import ContactTagline from './sections/ContactTagline';

function Footer() {
    return (
        <>
            <ContactTagline />
            <footer className="pad-30">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3 col-sm-3 col-xs-12">
                            <div className="logoOuter">
                                <img src="../assets/images/sanjay-sports-logo.png" alt="Sanjay Sports Academy" title="Sanjay Sports Academy" />
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-3 col-xs-12">
                            <h6>Quick Links</h6>
                            <ul className="linkArea">
                                {/* Update links using React Router's Link component */}
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/about-us">About Us</Link></li>
                                <li><Link to="/sports-facilities">Sports Facilities</Link></li>
                                <li><Link to="/news-and-events">Sanjay Sports Events</Link></li>
                                <li><Link to="/corporates">Corporates</Link></li>
                                <li><Link to="/training-coachings">Training & Coachings</Link></li>
                                <li><Link to="/contact-us">Contact Us</Link></li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <h6>Contact Us</h6>
                            <div className="contactBg">
                                <ul className="socialArea">
                                    <li><span>Address:</span> 19/1b/1, Near Hotel Rajwada, Baner Road, Someshwarwadi, Pune-411008.</li>
                                    <li><span>Phone:</span> 7028006577, 9503706844</li>
                                    <li><span>Email:</span> nimhan777@gmail.com</li>
                                </ul>
                                <ul className="SocialOuter">
                                    <li className="facebook">
                                        <a href="https://www.facebook.com/sanjayfutsal/" title="facebook" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                    </li>
                                    <li className="instagram">
                                        <Link to="https://instagram.com/sanjaysportsacademy?igshid=MzRlODBiNWFlZA== " title="Instagram"><i className="fa fa-instagram" aria-hidden="true"></i></Link>
                                    </li>
                                    <li className="youtube">
                                        <a href="#" title="Youtube">
                                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-3 col-xs-12">
                            {/* Additional content for this column if needed */}
                        </div>
                    </div>
                </div>
            </footer>

            <section className="footlinks">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <p>© 2020 | Design by <a href="https://sailotechnosoft.com/" target="_blank">Sailo Technosoft</a></p>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}

export default Footer;
