import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function BannerCarousel() {
    const options = {
        items: 1, // Number of items to display
        loop: true, // Infinite loop
        nav: true, // Show navigation arrows
        dots: false, // Hide navigation dots
        autoplay: true, // Autoplay the carousel
        autoplayTimeout: 5000, // Autoplay timeout in milliseconds
        smartSpeed: 1000, // Transition speed
        navClass: ['left carousel-control', 'right carousel-control'], // Change the default class names
    };

    return (
        <>
            <OwlCarousel className="owl-theme" {...options} id="carousel-example-generic">
                <div className="item">
                    <img src="../../assets/images/banner/new-banner-1.jpeg" alt="Sanjay Sports Academy" title="Sanjay Sports Academy" />
                </div>
                <div className="item ">
                    <img src="../../assets/images/banner/banner-003.jpg" alt="Sanjay Sports Academy" title="Sanjay Sports Academy" />
                    <div className="carousel-caption">
                        <h1 className="wow fadeInLeft" data-wow-delay="0.4s">
                            Age Is No Barrier For Sports, It’s A Limitation You Put In Your Mind
                        </h1>
                    </div>
                </div>
                <div className="item">
                    <img src="../../assets/images/banner/banner-1.jpg" alt="Sanjay Sports Academy" title="Sanjay Sports Academy" />
                    {/* Uncomment the following code if you want to add a caption to the second slide */}
                    {/* <div className="carousel-caption">
<h1 className="wow fadeInLeft" data-wow-delay="0.4s">
The Right Place For Your Kids, At Right Time
</h1>
</div> */}
                </div>

            </OwlCarousel>
        </>
    );
}

export default BannerCarousel;
