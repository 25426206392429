import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import ScrollToTopButton from './components/ScrollToTopButton';
import SportsFacilities from './pages/SportsFacilities';
import SanjaySportsEvents from './pages/SanjaySportsEvents';
import Corporate from './pages/Corporate';
import TrainingAndCoachings from './pages/TrainingAndCoachings';
import SanjayFutsalPage from './pages/SanjayFutsalPage'
import GalleryMedia from './pages/GalleryMedia';

function App() {

  return (
    <Router>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/sports-facilities" element={<SportsFacilities />} />
        <Route path="/news-and-events" element={<SanjaySportsEvents />} />
        <Route path="/corporates" element={<Corporate />} />
        <Route path="/training-coachings" element={<TrainingAndCoachings />} />
        <Route path='/Sanjay-Futsal' element={<SanjayFutsalPage />} />
        <Route path='/gallery' element={<GalleryMedia />} />
      </Routes>
      <ScrollToTopButton />

    </Router>
  );
}

export default App;
