import React from 'react';
import { Link } from 'react-router-dom';

function NewsAndEventsSection() {
    return (
        <section id="newsEventsArea" className="pad-30">
            <div className="container-fluid">
                <div className="row text-center">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="commanHeading">
                            <h1> Sanjay Sports Events </h1>
                        </div>
                    </div>
                </div>
                <div className="row" id="demo14">
                    {/* News items */}
                    {/* News item 1 */}
                    <div className="col-md-3 col-sm-6 col-xs-12 hover14">
                        <div className="newsOuter">
                            <h3>7<sup>th</sup> Sanjay Mahadeo Nimhan Memorial Basketball U-16 District Tournament </h3>
                            <figure>
                                <img src="../../assets/images/news-04.jpg" alt="7th Sanjay Mahadeo Nimhan Memorial Basketball U-16 District Tournament" title="7th Sanjay Mahadeo Nimhan Memorial Basketball U-16 District Tournament" />
                            </figure>
                            <div className="newstext">
                                <Link to="news-and-events">
                                    <p>7<sup>th</sup> Sanjay Mahadeo Nimhan Memorial Basketball U-16 District Tournament </p>
                                </Link>
                                <span> <i className="fa fa-calendar"></i>  24 May 2018 </span>
                            </div>
                        </div>
                    </div>

                    {/* News item 2 */}
                    <div className="col-md-3 col-sm-6 col-xs-12 hover14">
                        <div className="newsOuter">
                            <h3> Mr. Chintamani Nimhan (Chairman, Sanjay Sports Academy) felicitating Master. Yatin Mangwani for getting Selected in the U-19 Indian Cricket Team. </h3>
                            <figure>
                                <img src="../../assets/images/news-03.jpg" alt="Mr. Chintamani Nimhan (Chairman, Sanjay Sports Academy) felicitating Master. Yatin Mangwani for getting Selected in the U-19 Indian Cricket Team" title="Mr. Chintamani Nimhan (Chairman, Sanjay Sports Academy) felicitating Master. Yatin Mangwani for getting Selected in the U-19 Indian Cricket Team" />
                            </figure>
                            <div className="newstext">
                                <Link to="news-and-events">
                                    <p> Mr. Chintamani Nimhan (Chairman, Sanjay Sports Academy) felicitating Master. Yatin Mangwani for getting Selected in the U-19 Indian Cricket Team. </p>
                                </Link>
                                <span> <i className="fa fa-calendar"></i> 14 June 2018 </span>
                            </div>
                        </div>
                    </div>

                    {/* News item 3 */}
                    <div className="col-md-3 col-sm-6 col-xs-12 hover14">
                        <div className="newsOuter">
                            <h3> IBM's Sports Day celebrated at Sanjay Sports Academy managed by Wizcraft Events. </h3>
                            <figure>
                                <img src="../../assets/images/news-02.png" alt="IBM's Sports Day celebrated at Sanjay Sports Academy" title="IBM's Sports Day celebrated at Sanjay Sports Academy" />
                            </figure>
                            <div className="newstext">
                                <Link to="news-and-events">
                                    <p> IBM's Sports Day celebrated at Sanjay Sports Academy managed by Wizcraft Events. All you need is a break from everyday's work and play sports. </p>
                                </Link>
                                <span> <i className="fa fa-calendar"></i> 9 September 2018 </span>
                            </div>
                        </div>
                    </div>

                    {/* News item 4 */}
                    <div className="col-md-3 col-sm-6 col-xs-12 hover14">
                        <div className="newsOuter">
                            <h3> Chintamani Nimhan Cricket Academy Wins The Verroc Vengserkar Trophy </h3>
                            <figure>
                                <img src="../../assets/images/news-01.jpg" alt="Chintamani Nimhan Cricket Academy Wins The Verroc Vengserkar Trophy" title="Chintamani Nimhan Cricket Academy Wins The Verroc Vengserkar Trophy" />
                            </figure>
                            <div className="newstext">
                                <Link to="news-and-events">
                                    <p> Chintamani Nimhan Cricket Academy Wins The Verroc Vengserkar Trophy Under 12 2018 By beating Vengserkar Academy which is One of the Best... </p>
                                </Link>
                                <span> <i className="fa fa-calendar"></i>  23 October 2018 </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default NewsAndEventsSection;
