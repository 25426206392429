import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
const ContactBanner = () => (
    <section className="inner-page-banner-area contact">
        <div className="container">
            <div className="row bannertext">
                <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="banner-caption">
                        <h1>Contact Us</h1>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-6 breadcrumbs-area">
                    <ol className="breadcrumb">
                        <li><Link href="index.html">Home</Link></li>
                        <li>Contact Us</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
);

const ContactInfo = () => (
    <div className="container-fluid pad-30 contactOuterArea wow fadeIn" id="InternalPageArea" style={{ visibility: 'visible', animationName: 'fadeIn' }}>
        <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                <div className="contactArea">
                    <p>
                        <span> <i className="fa fa-map-marker"></i> 19/1b/1, Near Hotel Rajwada, Baner Road, Someshwarwadi, Pune-411008. </span>
                    </p>
                    <p>
                        <span> <i className="fa fa-phone"></i> 7028006577, 9503706844 </span>
                    </p>
                    <p>
                        <span> <i className="fa fa-phone"></i> 8975909602 - Boxing & Kick Boxing Academy </span>
                    </p>
                    <p>
                        <span> <i className="fa fa-envelope-o"></i> <a href="mailto:nimhan777@gmail.com"> nimhan777@gmail.com </a> </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
);

const ContactForm = () => (
    <div className="container pad-30">
        <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
                <form className="form-horizontal formStyle" method="post" action="#" id="seeDemoForm">
                    <div className="validateContainer">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <p> All fields marked with an <span>*</span> are required fields. </p>
                            </div>
                            <div className="col-md-4">
                                <label className="control-label">Name <span>*</span></label>
                                <div className="form-group">
                                    <div className="validateField">
                                        <input type="text" className="form-control validateRequired validateAlphaonly" name="name" id="name" placeholder="Name" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <label className="control-label">Email ID <span>*</span></label>
                                <div className="form-group">
                                    <div className="validateField">
                                        <input type="text" className="form-control validateRequired validateEmail" name="email" id="email" placeholder="Email ID" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <label className="control-label">Phone No. <span>*</span></label>
                                <div className="form-group">
                                    <div className="validateField">
                                        <input
                                            id="phone"
                                            name="phone"
                                            className="form-control validateRequired validateNumber validateMobileNoLimit"
                                            minLength="6"
                                            maxLength="12"
                                            placeholder="Phone No."
                                            type="text"
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <label className="control-label">Services <span>*</span></label>
                                <div className="form-group">
                                    <div className="validateField">
                                        <select className="form-control validateRequired" name="service" id="service">
                                            <option value=""> Select </option>
                                            <option value="Cricket"> Cricket </option>
                                            <option value="Basketball"> Basketball </option>
                                            <option value="Swimming"> Swimming </option>
                                            <option value="Football"> Football </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <label className="control-label"> Message </label>
                                <div className="form-group">
                                    <div className="validateField">
                                        <textarea className="form-control" id="message" name="message" rows="4" placeholder="Write a Message"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <input type="submit" className="btn btn-default checkValidationBtn submitBtn" value="Submit" />
                                <input type="reset" className="btn btn-default resetBtn" value="Reset" />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
);

const ContactMap = () => (
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.639777459295!2d73.80155131530842!3d18.545173573260556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf19acaf9031%3A0xb8510c95296cf2bf!2sSanjay%20Sports%20Academy!5e0!3m2!1sen!2sin!4v1575376880518!5m2!1sen!2sin"
        width="100%"
        height="350"
        frameBorder="0"
        style={{ border: '0' }}
        allowFullScreen=""
    ></iframe>
);

function Contact() {
    const pageTitle = ':: Contact Us | Sanjay Sports Academy ::';
    useEffect(() => {
        document.title = pageTitle;
    }, [pageTitle]);
    return (
        <div>
            <Header />
            <ContactBanner />
            <ContactInfo />
            <ContactForm />
            <ContactMap />
            <Footer />
        </div>
    );
}
export default Contact;
