import React from "react";
import SanjayFutsal from '../components/SanjayFutsal';

const SanjayFutsalPage = () => (
    <div>
        <SanjayFutsal />
    </div>
);

export default SanjayFutsalPage;
