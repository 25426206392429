import React, { useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MyGallery from '../components/MyGallery'; // Adjust the path as needed
import $ from 'jquery';

function addToGallery(imageSources, caption) {
    const gallery = [];
    imageSources.forEach((src) => {
        gallery.push({
            "src": `../assets/${src}`,
            "thumbnail": `../assets/${src}`,
            "caption": caption
        });
    });
    return gallery;
}

const NewsAccordion = () => {



    const imageSources = [
        "images/gallery/1.jpg",
        "images/gallery/2.jpg",
        "images/gallery/3.jpg",
        "images/gallery/4.jpg",
        "images/gallery/5.jpg",
        "images/gallery/6.jpg",
        "images/gallery/7.jpg",
        "images/gallery/8.jpg",
        "images/gallery/9.jpg"
    ];
    const galleryOne = addToGallery(imageSources, "Chintamani Nimhan Cricket Academy Wins The Verroc Vengserkar Trophy");
    const imageSourcesTwo = [
        "images/gallery/ibm-sport-day/1.jpg",
        "images/gallery/ibm-sport-day/2.jpg",
        "images/gallery/ibm-sport-day/3.jpg",
        "images/gallery/ibm-sport-day/4.jpg"
    ];
    const galleryTwo = addToGallery(imageSourcesTwo, "IBM's Sports Day celebrated at Sanjay Sports Academy managed by Wizcraft Events");

    const imageSourcesThree = [
        "images/gallery/master-yatin-mangwani/1.jpg",
        "images/gallery/master-yatin-mangwani/2.jpg",
        "images/gallery/master-yatin-mangwani/3.jpg",
        "images/gallery/master-yatin-mangwani/4.jpg",
        "images/gallery/master-yatin-mangwani/5.jpg",
        "images/gallery/master-yatin-mangwani/6.jpg",
        "images/gallery/master-yatin-mangwani/7.jpg",
        "images/gallery/master-yatin-mangwani/8.jpg",
        "images/gallery/master-yatin-mangwani/9.jpg",
        "images/gallery/master-yatin-mangwani/10.jpg"
    ];
    const galleryThree = addToGallery(imageSourcesThree, "Mr.Chintamani Nimhan (Chairman , Sanjay Sports Academy) felicitating Master.Yatin Mangwani for getting Selected in the U-19 Indian Cricket Team");
    const imageSourcesFour = [
        "images/gallery/memorial-basketball/1.jpg",
        "images/gallery/memorial-basketball/2.jpg",
        "images/gallery/memorial-basketball/3.jpg",
        "images/gallery/memorial-basketball/4.jpg",
        "images/gallery/memorial-basketball/5.jpg",
        "images/gallery/memorial-basketball/6.jpg",
        "images/gallery/memorial-basketball/7.jpg",
        "images/gallery/memorial-basketball/8.jpg",
        "images/gallery/memorial-basketball/9.jpg",
        "images/gallery/memorial-basketball/10.jpg",
        "images/gallery/memorial-basketball/11.jpg",
        "images/gallery/memorial-basketball/12.jpg",
        "images/gallery/memorial-basketball/13.jpg",
        "images/gallery/memorial-basketball/14.jpg",
        "images/gallery/memorial-basketball/15.jpg"
    ];
    const galleryFour = addToGallery(imageSourcesFour, "7th Sanjay Mahadeo Nimhan Memorial Basketball U-16 District Tournament");

    const imageSourcesFive = [
        "images/gallery/chalk-cue/1.jpg",
        "images/gallery/chalk-cue/2.jpg",
        "images/gallery/chalk-cue/3.jpg",
        "images/gallery/chalk-cue/4.jpg",
        "images/gallery/chalk-cue/5.jpg",
        "images/gallery/chalk-cue/6.jpg",
        "images/gallery/chalk-cue/7.jpg",
        "images/gallery/chalk-cue/8.jpg",
        "images/gallery/chalk-cue/9.jpg"
    ];
    const galleryFive = addToGallery(imageSourcesFive, "Sanjay Mahadeo Nimhan Memorial Pune District Championship u-16");

    const imageSourcesSix = [
        "images/gallery/district-championship/1.jpg",
        "images/gallery/district-championship/2.jpg",
        "images/gallery/district-championship/3.jpg",
        "images/gallery/district-championship/4.jpg",
        "images/gallery/district-championship/5.jpg",
        "images/gallery/district-championship/6.jpg",
        "images/gallery/district-championship/7.jpg",
        "images/gallery/district-championship/8.jpg",
        "images/gallery/district-championship/9.jpg",
        "images/gallery/district-championship/10.jpg",
        "images/gallery/district-championship/11.jpg",
        "images/gallery/district-championship/12.jpg",
        "images/gallery/district-championship/13.jpg",
        "images/gallery/district-championship/14.jpg"
    ];
    const gallerySix = addToGallery(imageSourcesSix, "Sanjay Nimhan Memorial Tournament");

    const imageSourcesSeven = [
        "images/gallery/memorel-tournament/1.jpg",
        "images/gallery/memorel-tournament/2.jpg",
        "images/gallery/memorel-tournament/3.jpg",
        "images/gallery/memorel-tournament/4.jpg",
        "images/gallery/memorel-tournament/5.jpg",
        "images/gallery/memorel-tournament/6.jpg",
        "images/gallery/memorel-tournament/7.jpg",
        "images/gallery/memorel-tournament/8.jpg",
        "images/gallery/memorel-tournament/9.jpg",
        "images/gallery/memorel-tournament/10.jpg",
        "images/gallery/memorel-tournament/11.jpg",
        "images/gallery/memorel-tournament/12.jpg",
        "images/gallery/memorel-tournament/13.jpg",
        "images/gallery/memorel-tournament/14.jpg",
        "images/gallery/memorel-tournament/15.jpg"
    ];
    const gallerySeven = addToGallery(imageSourcesSeven, "Sanjay Nimhan Memorial Tournament 2K16");

    const imageSourcesEight = [
        "images/gallery/memorial-tournament-2k16/1.jpg",
        "images/gallery/memorial-tournament-2k16/2.jpg",
        "images/gallery/memorial-tournament-2k16/3.jpg",
        "images/gallery/memorial-tournament-2k16/4.jpg",
        "images/gallery/memorial-tournament-2k16/5.jpg",
        "images/gallery/memorial-tournament-2k16/6.jpg",
        "images/gallery/memorial-tournament-2k16/7.jpg",
        "images/gallery/memorial-tournament-2k16/8.jpg",
        "images/gallery/memorial-tournament-2k16/9.jpg",
        "images/gallery/memorial-tournament-2k16/10.jpg"
    ];
    const galleryEight = addToGallery(imageSourcesEight, "The PSFA (PUNE SLUM FOOTBALL ASSOCIATION) “MLA TROPHY 2014”");

    const imageSourcesNine = [
        "images/gallery/PSFA/1.jpg",
        "images/gallery/PSFA/2.jpg",
        "images/gallery/PSFA/3.jpg",
        "images/gallery/PSFA/4.jpg",
        "images/gallery/PSFA/5.jpg"
    ];
    const galleryNine = addToGallery(imageSourcesNine, "Sanjay Futsal Open Tournament - 6 days , 53 teams , Non - Stop Action");

    const imageSourcesTen = [
        "images/gallery/sanjay-futsal-tournament/1.jpg",
        "images/gallery/sanjay-futsal-tournament/2.jpg",
        "images/gallery/sanjay-futsal-tournament/3.jpg",
        "images/gallery/sanjay-futsal-tournament/4.jpg",
        "images/gallery/sanjay-futsal-tournament/5.jpg",
        "images/gallery/sanjay-futsal-tournament/6.jpg",
        "images/gallery/sanjay-futsal-tournament/7.jpg",
        "images/gallery/sanjay-futsal-tournament/8.jpg",
        "images/gallery/sanjay-futsal-tournament/9.jpg"
    ];
    const galleryTen = addToGallery(imageSourcesTen, "Sanjay Futsal Open Tournament - 6 days , 53 teams , Non - Stop Action");

    const imageSourcesEleven = [
        "images/gallery/galacticose-final/1.jpg",
        "images/gallery/galacticose-final/2.jpg",
        "images/gallery/galacticose-final/3.jpg",
        "images/gallery/galacticose-final/4.jpg",
        "images/gallery/galacticose-final/5.jpg",
        "images/gallery/galacticose-final/6.jpg",
        "images/gallery/galacticose-final/7.jpg",
        "images/gallery/galacticose-final/8.jpg",
        "images/gallery/galacticose-final/9.jpg",
        "images/gallery/galacticose-final/10.jpg"
    ];
    const galleryEleven = addToGallery(imageSourcesEleven, "Sanjay Futsal Open Tournament - 6 days , 53 teams , Non - Stop Action");

    const imageSourcesTwelve = [
        "images/gallery/runner-up/1.jpg",
        "images/gallery/galacticose-final/2.jpg",
        "images/gallery/galacticose-final/3.jpg",
        "images/gallery/galacticose-final/4.jpg",
        "images/gallery/galacticose-final/5.jpg",
        "images/gallery/galacticose-final/6.jpg",
        "images/gallery/galacticose-final/7.jpg",
        "images/gallery/galacticose-final/8.jpg",
        "images/gallery/galacticose-final/9.jpg",
        "images/gallery/galacticose-final/10.jpg"
    ];
    const galleryTwelve = addToGallery(imageSourcesTwelve, "Sanjay Futsal Open Tournament - 6 days , 53 teams , Non - Stop Action");

    const imageSourcesThirteen = [
        "images/gallery/night-futsal/1.jpg",
        "images/gallery/night-futsal/2.jpg",
        "images/gallery/night-futsal/3.jpg",
        "images/gallery/night-futsal/4.jpg",
        "images/gallery/night-futsal/5.jpg",
        "images/gallery/night-futsal/6.jpg"
    ];
    const galleryThirteen = addToGallery(imageSourcesThirteen, "Sanjay Futsal Open Tournament - 6 days , 53 teams , Non - Stop Action");

    const imageSourcesFourteen = [
        "images/gallery/futsal-flood-light/1.jpg",
        "images/gallery/futsal-flood-light/2.jpg",
        "images/gallery/futsal-flood-light/3.jpg",
        "images/gallery/futsal-flood-light/4.jpg",
        "images/gallery/futsal-flood-light/5.jpg",
        "images/gallery/futsal-flood-light/6.jpg",
        "images/gallery/futsal-flood-light/7.jpg",
        "images/gallery/futsal-flood-light/8.jpg",
        "images/gallery/futsal-flood-light/9.jpg",
        "images/gallery/futsal-flood-light/10.jpg",
        "images/gallery/futsal-flood-light/11.jpg"
    ];
    const galleryFourteen = addToGallery(imageSourcesFourteen, "Sanjay Futsal Open Tournament - 6 days , 53 teams , Non - Stop Action");

    const imageSourcesFifteen = [
        "images/gallery/inaugural-match/1.jpg",
        "images/gallery/inaugural-match/2.jpg",
        "images/gallery/inaugural-match/3.jpg",
        "images/gallery/inaugural-match/4.jpg",
        "images/gallery/inaugural-match/5.jpg",
        "images/gallery/inaugural-match/6.jpg",
        "images/gallery/inaugural-match/7.jpg",
        "images/gallery/inaugural-match/8.jpg",
        "images/gallery/inaugural-match/9.jpg",
        "images/gallery/inaugural-match/10.jpg",
        "images/gallery/inaugural-match/11.jpg",
        "images/gallery/inaugural-match/12.jpg",
        "images/gallery/inaugural-match/13.jpg",
        "images/gallery/inaugural-match/14.jpg",
        "images/gallery/inaugural-match/15.jpg",
        "images/gallery/inaugural-match/16.jpg"
    ];
    const galleryFifteen = addToGallery(imageSourcesFifteen, "Sanjay Futsal Open Tournament - 6 days , 53 teams , Non - Stop Action");



    return (
        <div className="row wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
            <div className="col-sm-12 col-xs-12">
                <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    {/* Panel 1 */}
                    <div className="panel panel-custom">
                        <div className="panel-heading" role="tab" id="headingOne">
                            <h4 className="panel-title">
                                <a
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                >
                                    <i className="glyphicon glyphicon-plus"></i>
                                    Chintamani Nimhan Cricket Academy Wins The Verroc Vengserkar Trophy
                                </a>
                            </h4>
                        </div>
                        <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                            <div className="panel-body ">
                                {/* Contents */}
                                <p>
                                    Chintamani Nimhan Cricket Academy Wins The Verroc Vengserkar Trophy Under 12 2018 By beating Vengserkar Academy which is One of the Best team in Maharashtra by 35 Runs. Nilay Kashid was The Best Bowler of the Tournament. Congrats Boys , keep it up !
                                </p>

                                <span> <i className="fa fa-calendar"></i> 23 October 2018 </span>

                                {/* Gallery */}
                                <div className="row galleryOuter">
                                    {/* Add your gallery items here */}
                                    <MyGallery images={galleryOne} />
                                </div>
                                {/* Gallery */}
                            </div>
                        </div>
                    </div>

                    {/* Panel 2 */}
                    <div className="panel panel-custom">
                        <div className="panel-heading" role="tab" id="headingTwo">
                            <h4 className="panel-title">
                                <a
                                    className="collapsed"
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                >
                                    <i className="glyphicon glyphicon-plus"></i>
                                    IBM's Sports Day celebrated at Sanjay Sports Academy managed by Wizcraft Events
                                </a>
                            </h4>
                        </div>
                        <div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                            <div className="panel-body ">
                                <p>IBM's Sports Day celebrated at Sanjay Sports Academy managed by Wizcraft Events.</p>

                                <p>All you need is a break from everyday's work and play sports together to understand the value of team.</p>

                                <span> <i className="fa fa-calendar"></i> 9 September 2018 </span>

                                {/* Gallery */}
                                <div className="row galleryOuter">
                                    {/* Add your gallery items here */}
                                    <MyGallery images={galleryTwo} />
                                </div>
                                {/* Gallery */}
                            </div>
                        </div>
                    </div>

                    {/* Panel 3 */}
                    <div className="panel panel-custom">
                        <div className="panel-heading" role="tab" id="headingThree">
                            <h4 className="panel-title">
                                <a
                                    className="collapsed"
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                >
                                    <i className="glyphicon glyphicon-plus"></i>
                                    Mr.Chintamani Nimhan (Chairman , Sanjay Sports Academy) felicitating Master.Yatin Mangwani for getting Selected in the U-19 Indian Cricket Team
                                </a>
                            </h4>
                        </div>
                        <div id="collapseThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                            <div className="panel-body ">
                                <p>Mr. Chintamani Nimhan (Chairman , Sanjay Sports Academy) felicitating Master. Yatin Mangwani for getting Selected in the U-19 Indian Cricket Team.</p>

                                <span> <i className="fa fa-calendar"></i> 14 June 2018 </span>

                                {/* Gallery */}
                                <div className="row galleryOuter">
                                    {/* Add your gallery items here */}
                                    <MyGallery images={galleryThree} />
                                </div>
                                {/* Gallery */}
                            </div>
                        </div>
                    </div>

                    {/* Panel 4 */}
                    <div className="panel panel-custom">
                        <div className="panel-heading" role="tab" id="headingFour">
                            <h4 className="panel-title">
                                <a
                                    className="collapsed"
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href="#collapseFour"
                                    aria-expanded="false"
                                    aria-controls="collapseFour"
                                >
                                    <i className="glyphicon glyphicon-plus"></i>
                                    7<sup>th</sup> Sanjay Mahadeo Nimhan Memorial Basketball U-16 District Tournament
                                </a>
                            </h4>
                        </div>
                        <div id="collapseFour" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                            <div className="panel-body ">
                                <p>7<sup>th</sup> Sanjay Mahadeo Nimhan Memorial Basketball U-16 District Tournament</p>

                                <span> <i className="fa fa-calendar"></i> 24 May 2018 </span>

                                {/* Gallery */}
                                <div className="row galleryOuter">
                                    {/* Add your gallery items here */}
                                    <MyGallery images={galleryFour} />
                                </div>
                                {/* Gallery */}
                            </div>
                        </div>
                    </div>
                    {/* Panel 5 */}
                    <div className="panel panel-custom">
                        <div className="panel-heading" role="tab" id="headingFive">
                            <h4 className="panel-title">
                                <a
                                    className="collapsed"
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href="#collapseFive"
                                    aria-expanded="false"
                                    aria-controls="collapseFive"
                                >
                                    <i className="glyphicon glyphicon-plus"></i>
                                    Sanjay Mahadeo Nimhan Memorial Pune District Championship u-16
                                </a>
                            </h4>
                        </div>
                        <div id="collapseFive" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
                            <div className="panel-body ">
                                <p>
                                    Sanjay Mahadeo Nimhan Memorial Pune District Championship u-16. Prize distribution by Chintamani Mahadeo Nimhan, Vice President of Pune District Basketball Association, and owner of Sanjay Sports Academy.
                                </p>
                                <span>
                                    <i className="fa fa-calendar"></i> 19 April 2017
                                </span>

                                {/* Gallery */}
                                <div className="row galleryOuter">
                                    {/* Add your gallery items here */}
                                    {/* Example gallery item */}

                                    <MyGallery images={galleryFive} />
                                    {/* Add more gallery items here */}
                                </div>
                                {/* Gallery */}
                            </div>
                        </div>
                    </div>
                    {/* Panel 5 */}

                    {/* Panel 6 */}
                    <div className="panel panel-custom">
                        <div className="panel-heading" role="tab" id="headingSix">
                            <h4 className="panel-title">
                                <a
                                    className="collapsed"
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href="#collapseSix"
                                    aria-expanded="false"
                                    aria-controls="collapseSix"
                                >
                                    <i className="glyphicon glyphicon-plus"></i>
                                    Sanjay Nimhan Memorial Tournament
                                </a>
                            </h4>
                        </div>
                        <div id="collapseSix" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSix">
                            <div className="panel-body ">
                                <p>Sanjay Nimhan Memorial Tournament.</p>
                                <span>
                                    <i className="fa fa-calendar"></i> 19 October 2016
                                </span>

                                {/* Gallery */}
                                <div className="row galleryOuter">
                                    {/* Add your gallery items here */}
                                    {/* Example gallery item */}

                                    <MyGallery images={gallerySix} />
                                    {/* Add more gallery items here */}
                                </div>
                                {/* Gallery */}
                            </div>
                        </div>
                    </div>
                    {/* Panel 6 */}

                    {/* Panel 7 */}
                    <div className="panel panel-custom">
                        <div className="panel-heading" role="tab" id="headingSeven">
                            <h4 className="panel-title">
                                <a
                                    className="collapsed"
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href="#collapseSeven"
                                    aria-expanded="false"
                                    aria-controls="collapseSeven"
                                >
                                    <i className="glyphicon glyphicon-plus"></i>
                                    Sanjay Nimhan Memorial Tournament 2K16
                                </a>
                            </h4>
                        </div>
                        <div id="collapseSeven" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSeven">
                            <div className="panel-body ">
                                <p>Sanjay Nimhan Memorial Tournament 2K16.</p>
                                <span>
                                    <i className="fa fa-calendar"></i> 7 October 2016
                                </span>

                                {/* Gallery */}
                                <div className="row galleryOuter">
                                    {/* Add your gallery items here */}
                                    {/* Example gallery item */}

                                    <MyGallery images={gallerySeven} />
                                    {/* Add more gallery items here */}
                                </div>
                                {/* Gallery */}
                            </div>
                        </div>
                    </div>
                    {/* Panel 7 */}

                    {/* Panel 8 */}
                    <div className="panel panel-custom">
                        <div className="panel-heading" role="tab" id="headingEight">
                            <h4 className="panel-title">
                                <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                    <i className="glyphicon glyphicon-plus"></i>
                                    The PSFA (PUNE SLUM FOOTBALL ASSOCIATION) “MLA TROPHY 2014”
                                </a>
                            </h4>
                        </div>
                        <div id="collapseEight" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingEight">
                            <div className="panel-body ">
                                <p> The PSFA (PUNE SLUM FOOTBALL ASSOCIATION)
                                    “MLA TROPHY 2014” started with BANG and ended with a BANGBANG!!
                                    The nine day football extravaganza for the underprivileged children saw some great action with lots of ups and down and had some great moments. Here are the final results of the MLA TROPHY 2014 </p>

                                <span> <i className="fa fa-calendar"></i> 31 July 2014 </span>



                                <div className="row galleryOuter">

                                    <MyGallery images={galleryEight} />

                                </div>

                            </div>
                        </div>
                    </div>
                    {/* Panel 9 */}
                    <div className="panel panel-custom">
                        <div className="panel-heading" role="tab" id="headingNine">
                            <h4 className="panel-title">
                                <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                    <i className="glyphicon glyphicon-plus"></i>
                                    Sanjay Futsal Open Tournament - 6 days , 53 teams , Non - Stop Action
                                </a>
                            </h4>
                        </div>
                        <div id="collapseNine" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingNine">
                            <div className="panel-body ">
                                <p> Sanjay Futsal Open Tournament - 6 days , 53 teams , Non - Stop Action </p>
                                <span> <i className="fa fa-calendar"></i> 20 May 2014 </span>
                                <div className="row galleryOuter">

                                    <MyGallery images={galleryNine} />
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* Panel 10 */}
                    <div className="panel panel-custom">
                        <div className="panel-heading" role="tab" id="headingTen">
                            <h4 className="panel-title">
                                <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                    <i className="glyphicon glyphicon-plus"></i>
                                    Galacticos(White) vs 911(Red) - Final
                                </a>
                            </h4>
                        </div>
                        <div id="collapseTen" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTen">
                            <div className="panel-body ">
                                <p> Galacticos(white) vs 911(Red) - Final </p>

                                <span> <i className="fa fa-calendar"></i> 20 May 2014 </span>

                                <div className="row galleryOuter">

                                    <MyGallery images={galleryTen} />
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* <!-- panel 11 --> */}
                    <div className="panel panel-custom">
                        <div className="panel-heading" role="tab" id="headingElevan">
                            <h4 className="panel-title">
                                <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseElevan" aria-expanded="false" aria-controls="collapseElevan">
                                    <i className="glyphicon glyphicon-plus"></i>
                                    911 - Runner Up
                                </a>
                            </h4>
                        </div>
                        <div id="collapseElevan" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingElevan">
                            <div className="panel-body ">
                                <p> 911 closely Miss out and are the worthy runner ups. </p>

                                <span> <i className="fa fa-calendar"></i> 19 June 2014  </span>


                                {/* <!-- gallery --> */}
                                <div className="row galleryOuter">

                                    <MyGallery images={galleryEleven} />
                                </div>
                                {/* <!-- gallery --> */}

                            </div>
                        </div>
                    </div>
                    {/* <!-- panel 11 --> */}

                    {/* <!-- panel 12 --> */}
                    <div className="panel panel-custom">
                        <div className="panel-heading" role="tab" id="headingTwelve">
                            <h4 className="panel-title">
                                <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                    <i className="glyphicon glyphicon-plus"></i>
                                    Night Futsal
                                </a>
                            </h4>
                        </div>
                        <div id="collapseTwelve" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwelve">
                            <div className="panel-body ">
                                <p> Night Futsal  </p>

                                <span> <i className="fa fa-calendar"></i> 25 April 2014 </span>


                                {/* <!-- gallery --> */}

                                <div className="row galleryOuter">

                                    <MyGallery images={galleryTwelve} />
                                </div>
                                {/* <!-- gallery --> */}

                            </div>
                        </div>
                    </div>
                    {/* <!-- panel 12 --> */}

                    {/* <!-- panel 13 --> */}
                    <div className="panel panel-custom">
                        <div className="panel-heading" role="tab" id="headingThirteen">
                            <h4 className="panel-title">
                                <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                    <i className="glyphicon glyphicon-plus"></i>
                                    Futsal under Flood Lights
                                </a>
                            </h4>
                        </div>
                        <div id="collapseThirteen" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThirteen">
                            <div className="panel-body ">
                                <p> Futsal under flood lights  </p>

                                <span> <i className="fa fa-calendar"></i> 19 April 2014 </span>


                                {/* <!-- gallery --> */}

                                <div className="row galleryOuter">

                                    <MyGallery images={galleryThirteen} />

                                </div>
                                {/* <!-- gallery --> */}

                            </div>
                        </div>
                    </div>
                    {/* <!-- panel 13 --> */}
                    {/* <!-- panel 14 --> */}
                    <div className="panel panel-custom">
                        <div className="panel-heading" role="tab" id="headingFourteen">
                            <h4 className="panel-title">
                                <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                    <i className="glyphicon glyphicon-plus"></i>
                                    Sanjay Futsal Inaugural Match
                                </a>
                            </h4>
                        </div>
                        <div id="collapseFourteen" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFourteen">
                            <div className="panel-body ">
                                <p> Inaugural Match between
                                    Nimhans (White) Vs Bahirats (Red)  <br />

                                    Final Score Nimhans 7-4 Bahirats  </p>

                                <span> <i className="fa fa-calendar"></i> 31 March 2014 </span>


                                {/* <!-- gallery --> */}

                                <div className="row galleryOuter">

                                    <MyGallery images={galleryFourteen} />

                                </div>
                                {/* <!-- gallery --> */}

                            </div>
                        </div>
                    </div>
                    {/* <!-- panel 14 --> */}

                </div>
            </div>
        </div>
    );
};


const SanjaySportsEvents = () => {

    return (
        <div>
            <Header />
            {/* Inner Page Banner */}
            <section className="inner-page-banner-area news">
                <div className="container">
                    <div className="row bannertext">
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="banner-caption">
                                <h1>Sanjay Sports Events</h1>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-6 breadcrumbs-area">
                            <ol className="breadcrumb">
                                <li><a href="index.html">Home</a></li>
                                <li>Sanjay Sports Events</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            {/* Container for Event Table */}
            <div className="container pad-40 wow fadeIn" id="InternalPageArea">
                <div className="row">
                    <div className="col-sm-12 col-xs-12">
                        <h3>Past Events</h3>
                        <div className="tableStyles">
                            <table style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th>Events</th>
                                        <th>Date</th>
                                        <th>Location</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Box Cricket Tournament</td>
                                        <td>Dec 15</td>
                                        <td>Sanjay Sports Academy, Pune</td>
                                    </tr>
                                    <tr>
                                        <td>Five-A-Side Football Tournament</td>
                                        <td>May 10</td>
                                        <td>Sanjay Sports Academy, Pune</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <NewsAccordion />
            </div>

            <Footer />
        </div>
    );
};

export default SanjaySportsEvents;
