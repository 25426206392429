import React from 'react';

function AwardsAndAchievementsSection() {
    return (
        <section id="videoArea" className="pad-30">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="commanHeading">
                            <h1> Awards & Achievements </h1>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12"></div>
                    <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
                        {/* Progress bars */}
                        <div className="row">
                            <div className="col-sm-6 col-md-3">
                                <div className="progress" data-percentage="20">
                                    <span className="progress-left">
                                        <span className="progress-bar"></span>
                                    </span>
                                    <span className="progress-right">
                                        <span className="progress-bar"></span>
                                    </span>
                                    <div className="progress-value">
                                        <div>
                                            20+<br />
                                            <span> Coaches</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3">
                                <div className="progress" data-percentage="40">
                                    <span className="progress-left">
                                        <span className="progress-bar"></span>
                                    </span>
                                    <span className="progress-right">
                                        <span className="progress-bar"></span>
                                    </span>
                                    <div className="progress-value">
                                        <div>
                                            40+<br />
                                            <span> Award Winners </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3">
                                <div className="progress" data-percentage="80">
                                    <span className="progress-left">
                                        <span className="progress-bar"></span>
                                    </span>
                                    <span className="progress-right">
                                        <span className="progress-bar"></span>
                                    </span>
                                    <div className="progress-value">
                                        <div>
                                            80+ <br />
                                            <span> Tournament Participation </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3">
                                <div className="progress" data-percentage="100">
                                    <span className="progress-left">
                                        <span className="progress-bar"></span>
                                    </span>
                                    <span className="progress-right">
                                        <span className="progress-bar"></span>
                                    </span>
                                    <div className="progress-value">
                                        <div>
                                            100+<br />
                                            <span> Sports Played </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Progress bars end */}
                    </div>
                </div>
                <div className="parallelogram">
                    <div className="triangle-right"> </div>
                    <div className="text">
                        <div className="awardOuter">
                            <div className="media">
                                <div className="media-left">
                                    <a href="#">
                                        <img src="../../assets/images/awards.png" alt="Verroc Vengserkar Trophy - 2018" title="Verroc Vengserkar Trophy - 2018" />
                                    </a>
                                </div>
                                <div className="media-body">
                                    <h3> Verroc Vengserkar Trophy - 2018 </h3>
                                    <p> Chintamani Nimhan Cricket Academy Wins The Verroc Vengserkar Trophy </p>
                                </div>
                            </div>
                        </div>
                        <div className="awardOuter">
                            <div className="media">
                                <div className="media-left">
                                    <a href="#">
                                        <img src="../../assets/images/awards.png" alt="MLA TROPHY - 2014" title="MLA TROPHY - 2014" />
                                    </a>
                                </div>
                                <div className="media-body">
                                    <h3> MLA TROPHY - 2014 </h3>
                                    <p> The PSFA (PUNE SLUM FOOTBALL ASSOCIATION) “MLA TROPHY 2014”. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AwardsAndAchievementsSection;
