import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'

function PhotoGallerySection() {
    return (
        <section id="gallery" className="pad-30">
            <div className="container-fluid">
                <div className="row text-center">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="commanHeading">
                            <h1> Photo Gallery </h1>
                        </div>
                    </div>
                </div>
                <div className="row" id="image-gallery">
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                        <div className="img-wrapper">
                            <Image image={{ src: '../../assets/images/gallery/5.jpg', title: "Gallery Image 1" }} />
                            <div className="img-overlay">
                                <i className="fa fa-plus-circle" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                        <div className="img-wrapper">
                            <Image image={{ src: '../../assets/images/gallery/6.jpg', title: "Gallery Image 2" }} />
                            <div className="img-overlay">
                                <i className="fa fa-plus-circle" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                        <div className="img-wrapper">
                            <Image image={{ src: '../../assets/images/gallery/9.jpg', title: "Gallery Image 3" }} />
                            <div className="img-overlay">
                                <i className="fa fa-plus-circle" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                        <div className="img-wrapper">
                            <Image image={{ src: '../../assets/images/gallery/3.jpg', title: "Gallery Image 4" }} />
                            <div className="img-overlay">
                                <i className="fa fa-plus-circle" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                        <Link to="/gallery" className="effect effect1"> View Photo Gallery </Link>
                    </div>
                </div>
                {/* <!-- End row --> */}
            </div>
            {/* <!-- End container --> */}
        </section>
    );
}

export default PhotoGallerySection;
