import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'; // Import styles
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
const SportsFacilities = () => {
    return (
        <div>
            <Header />
            {/* Inner Page Banner */}
            <section className="inner-page-banner-area facility">
                <div className="container">
                    <div className="row bannertext">
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="banner-caption">
                                <h1>Sports Facilities</h1>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-6 breadcrumbs-area">
                            <ol className="breadcrumb">
                                <li><Link href="index.html">Home</Link></li>
                                <li>Sports Facilities</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            {/* Container for Tab Content */}
            <div className="container pad-40" id="InternalPageArea">
                <div className="row tabStyles">
                    <div className="col-sm-12 col-xs-12">
                        <Tabs>
                            <TabList className="nav nav-tabs" role="tablist" id="services-tabber">
                                <Tab>Football</Tab>
                                <Tab>Cricket</Tab>
                                <Tab>Basketball</Tab>
                                <Tab>Swimming</Tab>
                            </TabList>

                            <TabPanel className="tab-content">
                                <div class="row">
                                    <div class="col-md-5 col-sm-5 col-xs-12">
                                        <div class="tabimg">
                                            <img src="../../assets/images/fooball.jpg" alt="Football" title="Football" />
                                        </div>
                                    </div>
                                    <div class="col-md-7 col-sm-7 col-xs-12">

                                        <p>  At Sanjay Sports Academy, training coaching starts from (timing) and the turnout of the students who trains from the best of the staff is remarkable. Sanjay Sports Academy has three dedicated grounds that are available for rent, for five a side football out of which two of them are Natural grass and one is an Astro turf ground. Along with training and coaching we also rent our football ground on rent on hourly basis.</p>

                                        <p> Regular tournaments are also conducted and there is a huge response to it. Also as a part of CSR activity, Sanjay Sports Academy has organizes Slum football tournament for the underprivileged children. Football – this game doesn’t need any introduction; immensely popular sport around the world has a huge fan base that includes people from all walks of life. Such is the popularity of this sport that people have adopted this game in their lifestyle. Played and enjoyed not only by the youth, but also adults who find time to play this game which is more than life, and is passion for many.</p>

                                        <p> India in the past few decades has developed a great liking for this game. With the rise in the game, many sport academies have come up with coaching and training facilities for the same. Sanjay Sports Academy provides the finest football coaching in Pune. Many of the students that are the part of the football coaching from the academy have made their mark in various football competition held across India.</p>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel className="tab-content">
                                <div class="row">
                                    <div class="col-md-5 col-sm-5 col-xs-12">
                                        <div class="tabimg">
                                            <img src="../../assets/images/cricket.jpg" alt="Cricket" title="Cricket" />
                                        </div>
                                    </div>
                                    <div class="col-md-7 col-sm-7 col-xs-12">



                                        <p>Sanjay Sports Academy is situated in Pashan, Pune and offers facilities like four practice nets and a dedicated ground for cricket training and guidance. The box cricket ground can also be rented on an hourly basis. Bookings can be done for corporate events and tournaments also, and these are highly encouraged as it provides a welcome break to working professionals who get very little time off from work to pursue leisure activities.
                                            The rental charges for the ground are quite flexible and vary depending on the requirement. The idea is to help cricket enthusiasts sharpen their skills and mould their performance for future success.
                                            Cricket players are treated like celebrities in India, a privilege which very few other sports players enjoy. Sachin Tendulkar is hailed as the ‘God of Cricket’. No wonder thousands of people dream every day of becoming a cricket player and being a part of the Indian cricket team. Parents enrol their children in cricket coaching schools to give them formal training in the sport and help them pursue a professional career in the same.</p>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel className="tab-content">
                                <div class="row">
                                    <div class="col-md-5 col-sm-5 col-xs-12">
                                        <div class="tabimg">
                                            <img src="../../assets/images/basketball.jpg" alt="Basketball" title="Basketball" />
                                        </div>
                                    </div>
                                    <div class="col-md-7 col-sm-7 col-xs-12">



                                        <p> A popular sport of the west, basketball is as famous as cricket and is played & enjoyed by people of almost all ages. Almost all schools and colleges have their basketball teams who are competing with each other at district, state and national levels. At Sanjay Sports Academy we provide basketball coaching for beginners as well advanced players.
                                            The basketball facility was launched in 2010 and since then the academy has been hosting and organizing various basketball tournaments at district as well state levels thus giving students an opportunity to play at a better level and also encourage them to perform better. </p>

                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel className="tab-content">
                                <div class="row">
                                    <div class="col-md-5 col-sm-5 col-xs-12">
                                        <div class="tabimg">
                                            <img src="../../assets/images/swimming.jpg" alt="Swimming" title="Swimming" />
                                        </div>
                                    </div>
                                    <div class="col-md-7 col-sm-7 col-xs-12">

                                        <p> ---- </p>

                                    </div>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default SportsFacilities;
