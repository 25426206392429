import React from 'react';

function CertificatesSection() {
    return (
        <section id="CertificateArea" className="pad-30">
            <div className="container-fluid">
                <div className="row CertificateOuter">
                    <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
                        <div className="commanHeading">
                            <h1> Welcome Member Of UHFF </h1>
                        </div>
                        <div className="uhfftext">
                            <p>
                                We are proud to be a member <b>UHFF</b> (United Health & Fitness Federation), a forum of fitness & sports organizations founded in 2016.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
                        <div className="sertImageOuter">
                            <img src="../../assets/images/uhff-certificate.jpg" alt="UHFF" title="UHFF" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CertificatesSection;
