import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
function TrainingSchedule() {
    return (
        <tbody>
            <tr>
                <h4>CRICKET NET BOOKING</h4>

                <div>
                    <h5>MONDAY TO FRIDAY</h5>
                </div>
                <div>Rs. 500/- FOR 2 HOURS</div>

                <hr />

                <div>
                    <h5>SATURDAY & SUNDAY</h5>
                </div>
                <div>Rs. 700/- FOR 2 HOURS</div>

                <hr />

                <h4>BASKET BALL COURT BOOKING</h4>

                <div>BASKET BALL COURT</div>
                <div>Rs. 500/- FOR 1 HOUR</div>

                <hr />

                <h4>FUTSAL BOOKING</h4>

                <div>6 AM TO 11 PM</div>
                <div>Rs. 1000/- FOR 1 HOUR</div>

                <hr />

                <h4>FOOTBALL COACHING CHARGES</h4>

                <div>
                    <h5>(MONTHLY CHARGES)</h5>
                </div>

                <div>MONDAY TO SATURDAY (5 DAYS)</div>
                <div>Rs. 2000 PER MONTH</div>

                <hr />

                <div>
                    <h5>MONTHLY CHARGES FOR ALTERNATE DAYS</h5>
                </div>

                <div>MONDAY-WEDNESDAY-FRIDAY OR TUESDAY-THURSDAY-SATURDAY</div>
                <div>Rs. 1000 PER MONTH</div>

                <hr />

                <h4>CRICKET COACHING CHARGES</h4>

                <div>
                    <h5>MONDAY TO FRIDAY</h5>
                </div>

                <div>MORNING - 7.30 AM TO 9.00 AM</div>
                <div>
                    <span>DURATION:</span> 1 MONTH
                </div>
                <div>
                    <span>FEES:</span> 1500
                </div>
                <div>
                    <span>REGISTRATION CHARGES:</span> 500
                </div>
                <div>
                    <span>TOTAL:</span> 2000
                </div>

                <hr />

                <div>EVENING - 4.00 PM TO 5.30 PM</div>
                <div>
                    <span>DURATION:</span> 2 MONTH
                </div>
                <div>
                    <span>FEES:</span> 2500
                </div>
                <div>
                    <span>REGISTRATION CHARGES:</span> 500
                </div>
                <div>
                    <span>TOTAL:</span> 3000
                </div>

                <hr />

                <div>5.30 TO 7.00 PM</div>
                <div>
                    <span>DURATION:</span> 3 MONTH
                </div>
                <div>
                    <span>FEES:</span> 3500
                </div>
                <div>
                    <span>REGISTRATION CHARGES:</span> 500
                </div>
                <div>
                    <span>TOTAL:</span> 4000
                </div>

                <hr />

                <div>
                    <h5>WEEKEND BATCH</h5>
                </div>

                <div>SATURDAY AND SUNDAY</div>
                <div>MORNING-7.00 TO 9.00 AM</div>
                <div>
                    <span>DURATION:</span> 3 MONTH
                </div>
                <div>
                    <span>FEES:</span> 2500
                </div>
                <div>
                    <span>REGISTRATION CHARGES:</span> 500
                </div>
                <div>
                    <span>TOTAL :</span> 3000
                </div>

                <hr />

                <div>EVENING-5.00 TO 7.00 PM</div>

                <hr />

                <h4>BASKET BALL COACHING CHARGES</h4>

                <div>
                    <h5>MONDAY TO FRIDAY</h5>
                </div>

                <div>
                    <span>BATCH TIME:</span> MORNING-8.00 AM TO 9.00 AM
                </div>
                <div>
                    <span>DURATION:</span> 1 MONTH
                </div>
                <div>
                    <span>FEES:</span> 1500
                </div>
                <div>
                    <span>REGISTRATION CHARGES:</span> 500
                </div>
                <div>
                    <span>TOTAL:</span> 2000
                </div>

                <hr />

                <div>
                    <span>BATCH TIME:</span> EVENING- 4.00 PM TO 5.00 PM
                </div>
                <div>
                    <span>DURATION:</span> 1 MONTH
                </div>
                <div>
                    <span>FEES:</span> 1500
                </div>
                <div>
                    <span>REGISTRATION CHARGES:</span> 500
                </div>
                <div>
                    <span>TOTAL:</span> 2000
                </div>

                <hr />

                <div>
                    <span>BATCH TIME:</span> 5.00 PM TO 6.00 PM
                </div>
                <div>
                    <span>DURATION:</span> 2 MONTH
                </div>
                <div>
                    <span>FEES:</span> 2500
                </div>
                <div>
                    <span>REGISTRATION CHARGES:</span> 500
                </div>
                <div>
                    <span>TOTAL:</span> 3000
                </div>

                <hr />

                <div>
                    <span>BATCH TIME:</span> 6.00 PM TO 7.00 PM
                </div>
                <div>
                    <span>DURATION:</span> 3 MONTH
                </div>
                <div>
                    <span>FEES:</span> 3500
                </div>
                <div>
                    <span>REGISTRATION CHARGES:</span> 500
                </div>
                <div>
                    <span>TOTAL:</span> 4000
                </div>
            </tr>
        </tbody>
    );
}


function TrainingAndCoachings() {
    return (
        <div>
            <Header />
            <section className="inner-page-banner-area why">
                <div className="container">
                    <div className="row bannertext">
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="banner-caption">
                                <h1>Training & Coachings</h1>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-6 breadcrumbs-area">
                            <ol className="breadcrumb">
                                <li><Link to="/">Home</Link></li>
                                <li>Training & Coachings</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container pad-40 wow fadeIn" id="InternalPageArea">
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="tagStyle">
                            <h5>View Training & Coachings Schedules</h5>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="tableStyle table-responsive hidden-xs">
                        <table className="table table-striped">
                            <tbody>
                                <tr>
                                    <th colSpan={5}>CRICKET NET BOOKING</th>
                                </tr>
                                <tr>
                                    <td colSpan={3}>MONDAY TO FRIDAY</td>
                                    <td colSpan={2}>500/- FOR 2 HOURS</td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>SATURDAY & SUNDAY</td>
                                    <td colSpan={2}>700/- FOR 2 HOURS</td>
                                </tr>
                                <tr>
                                    <th colSpan={5}>BASKET BALL COURT BOOKING</th>
                                </tr>
                                <tr>
                                    <td colSpan={3}>BASKET BALL COURT</td>
                                    <td colSpan={2}>500/- FOR 1 HOUR</td>
                                </tr>
                                <tr>
                                    <th colSpan={5}>FUTSAL BOOKING</th>
                                </tr>
                                <tr>
                                    <td colSpan={3}>6 AM TO 11 PM</td>
                                    <td colSpan={2}>1000/- FOR 1 HOUR</td>
                                </tr>
                                <tr>
                                    <th colSpan={5}>FOOTBALL COACHING CHARGES</th>
                                </tr>
                                <tr>
                                    <th colSpan={5}>(MONTHLY CHARGES)</th>
                                </tr>
                                <tr>
                                    <td colSpan={2}>MONDAY TO SATURDAY (5 DAYS)</td>
                                    <td colSpan={2}>PER MONTH</td>
                                    <td colSpan={1}>2000</td>
                                </tr>
                                <tr>
                                    <td colSpan={5}>MONTHLY CHARGES FOR ALTERNATE DAYS</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>MONDAY-WEDNESDAY-FRIDAY OR TUESDAY-THURSDAY-SATURDAY</td>
                                    <td colSpan={2}>PER MONTH</td>
                                    <td colSpan={1}>1000</td>
                                </tr>
                                <tr>
                                    <th colSpan={5}>CRICKET COACHING CHARGES</th>
                                </tr>
                                <tr>
                                    <td>MONDAY TO FRIDAY</td>
                                    <td>DURATION</td>
                                    <td>FEES</td>
                                    <td>REGISTRATION CHARGES</td>
                                    <td>TOTAL</td>
                                </tr>
                                <tr>
                                    <td>MORNING - 7.30 AM TO 9.00 AM</td>
                                    <td>1 MONTH</td>
                                    <td>1500</td>
                                    <td>500</td>
                                    <td>2000</td>
                                </tr>
                                <tr>
                                    <td>EVENING - 4.00 PM TO 5.30 PM</td>
                                    <td>2 MONTH</td>
                                    <td>2500</td>
                                    <td>500</td>
                                    <td>3000</td>
                                </tr>
                                <tr>
                                    <td>5.30 TO 7.00 PM</td>
                                    <td>3 MONTH</td>
                                    <td>3500</td>
                                    <td>500</td>
                                    <td>4000</td>
                                </tr>
                                <tr>
                                    <th colSpan={5}>WEEKEND BATCH</th>
                                </tr>
                                <tr>
                                    <td>SATURDAY AND SUNDAY</td>
                                    <td>DURATION</td>
                                    <td>FEES</td>
                                    <td>REGISTRATION CHARGES</td>
                                    <td>TOTAL</td>
                                </tr>
                                <tr>
                                    <td>MORNING-7.00 TO 9.00 AM</td>
                                    <td>3 MONTH</td>
                                    <td>2500</td>
                                    <td>500</td>
                                    <td>3000</td>
                                </tr>
                                <tr>
                                    <td>EVENING-5.00 TO 7.00 PM</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th colSpan={5}>BASKET BALL COACHING CHARGES</th>
                                </tr>
                                <tr>
                                    <td>MONDAY TO FRIDAY</td>
                                    <td>DURATION</td>
                                    <td>FEES</td>
                                    <td>REGISTRATION CHARGES</td>
                                    <td>TOTAL</td>
                                </tr>
                                <tr>
                                    <td>BATCH TIME</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>MORNING-8.00 AM TO 9.00 AM</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>EVENING- 4.00 PM TO 5.00 PM</td>
                                    <td>1 MONTH</td>
                                    <td>1500</td>
                                    <td>500</td>
                                    <td>2000</td>
                                </tr>
                                <tr>
                                    <td>5.00 PM TO 6.00 PM</td>
                                    <td>2 MONTH</td>
                                    <td>2500</td>
                                    <td>500</td>
                                    <td>3000</td>
                                </tr>
                                <tr>
                                    <td>6.00 PM TO 7.00 PM</td>
                                    <td>3 MONTH</td>
                                    <td>3500</td>
                                    <td>500</td>
                                    <td>4000</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    {/* Table for mobile */}
                    <div className="tableStyle table-responsive visible-xs hidden-lg hidden-md hidden-sm">
                        <table className="table table-striped">
                            <TrainingSchedule />
                        </table>
                    </div>
                    {/* Table for mobile */}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default TrainingAndCoachings;
