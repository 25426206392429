import React, { Component } from 'react';

class ScrollToTopButton extends Component {
    constructor() {
        super();
        this.state = {
            isScrollVisible: false,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if (window.scrollY > 200) {
            this.setState({ isScrollVisible: true });
        } else {
            this.setState({ isScrollVisible: false });
        }
    };

    scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    render() {
        const { isScrollVisible } = this.state;

        return (
            <div>
                {isScrollVisible && (
                    <button className="scroll-top" style={{ display: 'block' }} onClick={this.scrollToTop}>
                        <i className="fa fa-angle-up"></i>
                    </button>
                )}
            </div>
        );
    }
}

export default ScrollToTopButton;
