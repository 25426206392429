import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MyGallery from '../components/MyGallery'; // Adjust the path as needed


function GalleryMedia() {
    const [activeTab, setActiveTab] = useState('football');

    const handleTabSelect = (eventKey) => {
        setActiveTab(eventKey);
    };

    const gallery = [
        {
            "src": "../assets/images/gallery/2.jpg",
            "thumbnail": "../assets/images/gallery/2.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/3.jpg",
            "thumbnail": "../assets/images/gallery/3.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/2.jpg",
            "thumbnail": "../assets/images/gallery/2.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/3.jpg",
            "thumbnail": "../assets/images/gallery/3.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/4.jpg",
            "thumbnail": "../assets/images/gallery/4.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/5.jpg",
            "thumbnail": "../assets/images/gallery/5.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/ibm-sport-day/2.jpg",
            "thumbnail": "../assets/images/gallery/ibm-sport-day/2.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/ibm-sport-day/4.jpg",
            "thumbnail": "../assets/images/gallery/ibm-sport-day/4.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/master-yatin-mangwani/1.jpg",
            "thumbnail": "../assets/images/gallery/master-yatin-mangwani/1.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/master-yatin-mangwani/4.jpg",
            "thumbnail": "../assets/images/gallery/master-yatin-mangwani/4.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/master-yatin-mangwani/8.jpg",
            "thumbnail": "../assets/images/gallery/master-yatin-mangwani/8.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/memorial-basketball/5.jpg",
            "thumbnail": "../assets/images/gallery/memorial-basketball/5.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/memorial-basketball/8.jpg",
            "thumbnail": "../assets/images/gallery/memorial-basketball/8.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/district-championship/3.jpg",
            "thumbnail": "../assets/images/gallery/district-championship/3.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/district-championship/5.jpg",
            "thumbnail": "../assets/images/gallery/district-championship/5.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/district-championship/7.jpg",
            "thumbnail": "../assets/images/gallery/district-championship/7.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/district-championship/9.jpg",
            "thumbnail": "../assets/images/gallery/district-championship/9.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/district-championship/12.jpg",
            "thumbnail": "../assets/images/gallery/district-championship/12.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/memorel-tournament/1.jpg",
            "thumbnail": "../assets/images/gallery/memorel-tournament/1.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/memorel-tournament/6.jpg",
            "thumbnail": "../assets/images/gallery/memorel-tournament/6.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/memorel-tournament/12.jpg",
            "thumbnail": "../assets/images/gallery/memorel-tournament/12.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/memorial-tournament-2k16/2.jpg",
            "thumbnail": "../assets/images/gallery/memorial-tournament-2k16/2.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/memorial-tournament-2k16/4.jpg",
            "thumbnail": "../assets/images/gallery/memorial-tournament-2k16/4.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/PSFA/3.jpg",
            "thumbnail": "../assets/images/gallery/PSFA/3.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/sanjay-futsal-tournament/2.jpg",
            "thumbnail": "../assets/images/gallery/sanjay-futsal-tournament/2.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/sanjay-futsal-tournament/3.jpg",
            "thumbnail": "../assets/images/gallery/sanjay-futsal-tournament/3.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/sanjay-futsal-tournament/5.jpg",
            "thumbnail": "../assets/images/gallery/sanjay-futsal-tournament/5.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/galacticose-final/1.jpg",
            "thumbnail": "../assets/images/gallery/galacticose-final/1.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/galacticose-final/3.jpg",
            "thumbnail": "../assets/images/gallery/galacticose-final/3.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/galacticose-final/7.jpg",
            "thumbnail": "../assets/images/gallery/galacticose-final/7.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/chalk-&-cue/1.jpg",
            "thumbnail": "../assets/images/gallery/chalk-&-cue/1.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/chalk-&-cue/3.jpg",
            "thumbnail": "../assets/images/gallery/chalk-&-cue/3.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/chalk-&-cue/5.jpg",
            "thumbnail": "../assets/images/gallery/chalk-&-cue/5.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/chalk-&-cue/7.jpg",
            "thumbnail": "../assets/images/gallery/chalk-&-cue/7.jpg",
            "caption": "Sanjay Sports Academy"
        }
    ];

    const press = [
        {
            "src": "../assets/images/gallery/chalk-&-cue/1.jpg",
            "thumbnail": "../assets/images/gallery/chalk-&-cue/1.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/chalk-&-cue/3.jpg",
            "thumbnail": "../assets/images/gallery/chalk-&-cue/3.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/chalk-&-cue/5.jpg",
            "thumbnail": "../assets/images/gallery/chalk-&-cue/5.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/chalk-&-cue/7.jpg",
            "thumbnail": "../assets/images/gallery/chalk-&-cue/7.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/1.jpg",
            "thumbnail": "../assets/images/gallery/1.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/memorial-basketball/1.jpg",
            "thumbnail": "../assets/images/gallery/memorial-basketball/1.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/press/1.jpg",
            "thumbnail": "../assets/images/gallery/press/1.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/press/2.jpg",
            "thumbnail": "../assets/images/gallery/press/2.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/press/3.jpg",
            "thumbnail": "../assets/images/gallery/press/3.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/press/4.jpg",
            "thumbnail": "../assets/images/gallery/press/4.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/press/5.jpg",
            "thumbnail": "../assets/images/gallery/press/5.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/press/6.jpg",
            "thumbnail": "../assets/images/gallery/press/6.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/press/8b.jpg",
            "thumbnail": "../assets/images/gallery/press/8b.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/press/9b.jpg",
            "thumbnail": "../assets/images/gallery/press/9b.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/press/10.jpg",
            "thumbnail": "../assets/images/gallery/press/10.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/press/11.jpg",
            "thumbnail": "../assets/images/gallery/press/11.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/press/12.jpg",
            "thumbnail": "../assets/images/gallery/press/12.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/press/13.jpg",
            "thumbnail": "../assets/images/gallery/press/13.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/press/14.jpg",
            "thumbnail": "../assets/images/gallery/press/14.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/press/15.jpg",
            "thumbnail": "../assets/images/gallery/press/15.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/press/16.jpg",
            "thumbnail": "../assets/images/gallery/press/16.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/press/17b.jpg",
            "thumbnail": "../assets/images/gallery/press/17b.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/press/18.jpg",
            "thumbnail": "../assets/images/gallery/press/18.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/press/19.jpg",
            "thumbnail": "../assets/images/gallery/press/19.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/press/20.jpg",
            "thumbnail": "../assets/images/gallery/press/20.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/press/21.jpg",
            "thumbnail": "../assets/images/gallery/press/21.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/press/22.jpg",
            "thumbnail": "../assets/images/gallery/press/22.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/press/23.jpg",
            "thumbnail": "../assets/images/gallery/press/23.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/press/24.jpg",
            "thumbnail": "../assets/images/gallery/press/24.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/press/25.jpg",
            "thumbnail": "../assets/images/gallery/press/25.jpg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/gallery/press/26.jpg",
            "thumbnail": "../assets/images/gallery/press/26.jpg",
            "caption": "Sanjay Sports Academy"
        }
    ];


    return (
        <><Header />
            <Container className="pad-40" id="InternalPageArea">
                <Row className="tabStyles">


                    <Tabs>
                        <TabList className="nav nav-tabs" role="tablist" id="services-tabber">
                            <Tab>Photos</Tab>
                            <Tab> Press release</Tab>

                        </TabList>

                        <TabPanel className="tab-content">
                            <MyGallery images={gallery} />
                        </TabPanel>
                        <TabPanel className="tab-content">
                            <MyGallery images={press} />
                        </TabPanel>
                    </Tabs>
                </Row>
            </Container><Footer /></>
    );
}

export default GalleryMedia;
