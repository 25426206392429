import React, { useEffect } from 'react';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'font-awesome/css/font-awesome.min.css';
import '../assets/css/animate.css'
import { Link } from 'react-router-dom';
import MyGallery from './MyGallery'; // Adjust the path as needed

function SanjayFutsal() {
    if (window.location.pathname === '/sanjay-futsal') {
        import('./SanjayFutsal.css').then(() => {
            // Special CSS is loaded
        });
    }
    const pageTitle = ':: Sanjay Futsal | Sanjay Sports Academy ::';
    const gallery = [
        {
            "src": "../assets/images/fut5al/1.jpeg",
            "thumbnail": "../assets/images/fut5al/1.jpeg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/fut5al/2.jpeg",
            "thumbnail": "../assets/images/fut5al/2.jpeg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/fut5al/3.jpeg",
            "thumbnail": "../assets/images/fut5al/3.jpeg",
            "caption": "Sanjay Sports Academy"
        },
        {
            "src": "../assets/images/fut5al/4.jpeg",
            "thumbnail": "../assets/images/fut5al/4.jpeg",
            "caption": "Sanjay Sports Academy"
        }

    ];
    useEffect(() => {
        // Initialize Tooltip (You can use a React tooltip library here)
        // $('[data-toggle="tooltip"]').tooltip();
        document.title = pageTitle;
        // Add smooth scrolling to all links in navbar + footer link
        const handleLinkClick = (event) => {
            const hash = event.target.getAttribute('href');
            if (hash && hash !== '#') {
                event.preventDefault();

                // Using React's state and smooth scroll
                const targetElement = document.querySelector(hash);
                if (targetElement) {
                    const offsetTop = targetElement.offsetTop;
                    window.scrollTo({
                        top: offsetTop,
                        behavior: 'smooth',
                    });

                    // Add hash (#) to URL when done scrolling (default click behavior)
                    window.history.pushState(null, null, hash);
                }
            }
        };

        // Attach the event listener to the document
        document.querySelectorAll('.navbar a, footer a[href^="#"]').forEach((link) => {
            link.addEventListener('click', handleLinkClick);
        });

        // Clean up the event listener when the component unmounts
        return () => {
            document.querySelectorAll('.navbar a, footer a[href^="#"]').forEach((link) => {
                link.removeEventListener('click', handleLinkClick);
            });
        };
    }, [pageTitle]);

    return (
        <div>
            <nav className="navbar navbar-default navbar-fixed-top sanjayfutsal">
                <div className="container">
                    <div className="navbar-header">
                        <button
                            type="button"
                            className="navbar-toggle"
                            data-toggle="collapse"
                            data-target="#myNavbar"
                        >
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <Link className="navbar-brand" to="/Sanjay-Futsal">
                            <img
                                src="../assets/images/sanjay-futsal-logo.png"
                                alt="Sanjay Futsal"
                                title="Sanjay Futsal"
                                style={{ width: '100px' }}
                            />
                        </Link>
                    </div>
                    <div className="collapse navbar-collapse" id="myNavbar">
                        <ul className="nav navbar-nav navbar-right">
                            <li>
                                <a href="#myPage">Home</a>
                            </li>
                            <li>
                                <a href="#band">About Us</a>
                            </li>
                            <li>
                                <a href="#tour">History</a>
                            </li>
                            <li>
                                <a href="#futsal">Why Futsal</a>
                            </li>
                            <li>
                                <a href="#contact">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div id="myPage" class="carousel-inner" role="listbox">
                <div class="item active">
                    <img src="../assets/images/banner.jpg" alt=" " title=" " width="1200" height="700" />
                    {/* <div class="carousel-caption">
                        <h1 class="wow fadeInRight" data-wow-delay="0.4s" style="visibility: visible; animation-delay: 0.4s; animation-name: fadeInRight;"> High Intensity, Fast Paced, Five-A-Side Futsal !!! </h1>
                    </div> */}
                </div>
            </div>
            {/* Container (The Band Section) */}
            <div className="about-bg">
                <div id="band" className="container fulwidth">
                    <div className="row">
                        <div className="col-sm-12 col-xs-12 col-md-12">
                            <h3>About Us</h3>
                            <p
                                className="wow fadeInUp"
                                style={{ visibility: 'visible', animationName: 'fadeInUp' }}
                            >
                                Futsal (Portuguese Pronunciation) is a variant of football that
                                is played on a smaller field and mainly played indoors. It is
                                official five-a-side indoor soccer game and the only indoor
                                soccer recognised by FIFA (Federation of Internationale Football
                                Association). Futsal is the game of 5 vs 5 players including the
                                goalkeepers and played on a small field roughly the size of a
                                basketball court. The game is played with the touchline
                                boundaries and does not require the use of dasher boards. The
                                official ball is a special low bounce ball that is smaller than
                                an official size soccer ball. It develops the overall fundamentals
                                of football more effectively. It has been proven that youth players
                                develop quicker reflexes, faster thinking and pinpoint passing. It
                                is a superior game in terms of establishing player’s technique.
                                Futsal is played in all the continents of the world by over 100
                                countries with 12 million players. The sport is a great skill
                                developer as it demands quick reflexes, fast thinking, pin-point
                                passing. The small and heavier Futsal ball requires players to
                                hone their ball-striking and ball handling technique. Great soccer
                                superstars such as Pele, Zico, Ronaldo, Messi, Kaka and Katia grew
                                up playing the Futsal.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* Container (TOUR Section) */}
            <div id="tour" className="bg-1">
                <div className="container fulwidth">
                    <h3>History</h3>
                    <div className="row">
                        <div
                            className="col-sm-12 col-xs-12 col-md-12 wow fadeIn"
                            style={{ visibility: 'visible', animationName: 'fadeIn' }}
                        >
                            <p>
                                Futsal was started in Montevideo, Uruguay, in 1930 when Juan
                                Carlos Ceriani created a version of football for competition in
                                YMCAs. In Brazil, this version developed on the streets of Sao
                                Paulo, and eventually a rule book was published. The sport began
                                to spread across South America, and its popularity ensured that
                                a governing body was formed under the name of FIFUSA (Federación
                                Internacional de Fútbol de Salón) in 1971, along with the World
                                Championships. The first FIFUSA World Championships were held in
                                São Paulo, with hosts Brazil crowned champions ahead of Paraguay
                                and Uruguay. Even more countries participated in the second World
                                Championships held in Madrid in 1985. Due to a dispute between
                                FIFA and FIFUSA over the administration of fútbol, FIFUSA coined
                                the word fut-sal in 1985. FIFA took control of the World
                                Championships in 1989. Under new rules made by FIFA, the technical
                                aspects of the game for players and spectators were improved. The
                                linesmen were replaced with a second referee and there were
                                unlimited substitutions. It also introduced a size 4 football,
                                which was weighted to reduce bounce by 30% compared to a
                                conventional ball, which enabled faster play and, for the first
                                time, scoring goals with the head. In 2004, members of PANAFUTSAL
                                (The Pan-American Futsal Confederation) formed AMF (Asociación
                                Mundial de Fútbol de Salón, World Futsal Association), an
                                international Futsal governing body independent of FIFA. And now
                                both FIFA and AMF continue to administer the game.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* Container (TOUR Section) */}
            {/* Container (FUTSAL Section) */}
            <div id="futsal">
                <div className="container fulwidth">
                    <h3>Why Futsal</h3>
                    <div className="row">
                        <div
                            className="col-sm-12 col-xs-12 col-md-12 wow fadeInUp"
                            style={{ visibility: 'visible', animationName: 'fadeInUp' }}
                        >
                            <ul className="futsalListing">
                                <li>
                                    <span>Fun</span> Players enjoy the challenge of playing a
                                    fast-paced skill-oriented game that tests their abilities.
                                </li>
                                <li>
                                    <span>Safety</span> Futsal is much safer than outdoor and
                                    some other forms of indoor soccer. The rules of Futsal
                                    restrict physical play, and there are no walls to run into.
                                </li>
                                <li>
                                    <span>Ball Touches</span> According to a FIFA report, in an
                                    average 40-minute Futsal contest, an outfield player will
                                    touch the ball roughly every 29 seconds, or 80 touches. Now
                                    compare that to a 90-minute outdoor game where it’s only
                                    around 30-40 touches. That is more than double in just half
                                    the time.
                                </li>
                                <li>
                                    <span>Ball Control</span> With Futsal, the emphasis is
                                    clearly on control and technique. Without control and
                                    technique, you cannot expect to succeed in Futsal. With
                                    limited space, boundary lines, and constant opponent pressure,
                                    improved ball control skills are required.
                                </li>
                                <li>
                                    <span>Speed of Play</span> With limited space and constant
                                    opponent pressure, players learn to play fast to survive.
                                </li>
                                <li>
                                    <span>Framing the Goal</span> The goal and Penalty Area are
                                    a perfect size for narrowing the angle so teams learn to frame
                                    the goal to score goals.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Container (FUTSAL Section) */}
            <div id="gallery">
                <div className="container fulwidth">
                    <h3>Gallery</h3>
                    <div className="row">
                        <MyGallery images={gallery} />
                    </div>
                </div>
            </div>
            {/* Container (Contact Section) */}
            <div className="container-fluid fulwidth pad-30" id="contact">
                <div className="row">
                    <div className="col-sm-12 col-xs-12 col-md-12">
                        <h3>Contact Us</h3>
                    </div>
                </div>
                <div
                    className="row wow fadeInUp"
                    style={{ visibility: 'visible', animationName: 'fadeInUp' }}
                >
                    <div className="col-sm-6 col-md-4 col-xs-12 ">
                        <div className="contactarea box0">
                            <p>
                                <i className="fa fa-map-marker"></i> 19/1b/1, Near Hotel
                                Rajwada, Baner Road, Someshwarwadi, Pune-411008.
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-xs-12">
                        <div className="contactarea box1">
                            <p>
                                <i className="fa fa-phone"></i> 7028006577, 9503706844
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-xs-12">
                        <div className="contactarea box2">
                            <p>
                                <i className="fa fa-envelope-o"></i> nimhan777@gmail.com
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-xs-12 col-md-12">
                        <div className="mapOuter">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.639777459295!2d73.80155131530842!3d18.545173573260556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf19acaf9031%3A0xb8510c95296cf2bf!2sSanjay%20Sports%20Academy!5e0!3m2!1sen!2sin!4v1575376880518!5m2!1sen!2sin"
                                width="100%"
                                height="200"
                                frameBorder="0"
                                style={{ border: '0' }}
                                allowFullScreen=""
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <div className="container-fluid">
                    <div className="row ">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <p>
                                © 2020 | Design by{' '}
                                <a
                                    href="https://sailotechnosoft.com/"
                                    target="_blank"
                                >
                                    Sailo Technosoft
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
            <button className="buttontop" style={{ display: 'block' }}>
                <i className="fa fa-angle-up"></i>{' '}
            </button>
            {/* jQuery (necessary for Bootstrap's JavaScript plugins) */}
            <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.4/jquery.min.js"></script>
        </div>
    );
}

export default SanjayFutsal;
