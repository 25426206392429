import React, { useEffect } from 'react';
import BannerCarousel from '../components/sections/BannerCarousel';
import FutsalSection from '../components/sections/FutsalSection';
import SportFacilitySection from '../components/sections/SportFacilitySection';
import BoxingSection from '../components/sections/BoxingSection';
import CertificatesSection from '../components/sections/CertificatesSection';
import NewsAndEventsSection from '../components/sections/NewsAndEventsSection';
import AwardsAndAchievementsSection from '../components/sections/AwardsAndAchievementsSection';
import PhotoGallerySection from '../components/sections/PhotoGallerySection';
import Header from '../components/Header';
import Footer from '../components/Footer';

function Home() {
    const pageTitle = ':: Sanjay Sports Academy ::';

    useEffect(() => {
        document.title = pageTitle;
    }, [pageTitle]);
    return (
        <div>
            <Header />
            <BannerCarousel />
            <section id="ProjectsArea" className="wow fadeIn pad-30">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <p>
                                Established in the year 2007, Sanjay Sports Academy is one of Pune’s leading sports clubs with provisions for a variety of outdoor sports facilities, namely: Cricket, Basketball, Football, and Swimming.
                                <br />
                                <br />
                                Situated at Pashan, Pune, the academy has been built on a ____ acre area and has all the latest state-of-the-art sporting equipment. With excellent amenities and other facilities in place, we are proud to say that Sanjay Sports Academy is the finest sports training center in the entire Pashan area. The academy believes in providing quality training at affordable costs.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <FutsalSection />
            <div className="sectionWrapper">
                <div id="section1" className="section">
                    <div className="sectionInner">
                        Sports Facilities
                    </div>
                </div>
            </div>
            <SportFacilitySection />
            <BoxingSection />
            <CertificatesSection />
            <NewsAndEventsSection />
            <AwardsAndAchievementsSection />
            <PhotoGallerySection />
            <Footer />
        </div>
    );
}
export default Home;