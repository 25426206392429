import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
function Corporate() {
    return (
        <div>
            <Header />
            <section className="inner-page-banner-area why">
                <div className="container">
                    <div className="row bannertext">
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="banner-caption">
                                <h1>Corporates</h1>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-6 breadcrumbs-area">
                            <ol className="breadcrumb">
                                <li><Link to="/">Home</Link></li>
                                <li>Corporates</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container pad-40 wow fadeIn" id="InternalPageArea">
                <div className="row">
                    <div className="col-sm-12 col-xs-12">
                        <h3>Introduction</h3>
                        <p>
                            The corporate world is all about meetings, deadlines, and profits, and in the course of managing all this, the corporate workforce tends to get exhausted. In our frequent interaction with HR managers, we understood that overall employee productivity is going down due to illness and other health-related problems. We realized that this can be solved through sports & fitness, thus launched exclusive corporate packages at the academy.
                        </p>

                        <div className="media corporateArea">
                            <div className="media-left">
                                <a href="#">
                                    <img className="media-object" src="../assets/images/icon/schedule.png" alt="Events" title=" Events" />
                                </a>
                            </div>
                            <div className="media-body">
                                <h4 className="media-heading">  Events </h4>
                                <p> From effective leadership to teambuilding skills, all can be achieved through sports. At Sanjay Sports Academy we have designed unique theme based outdoor sports tournaments that will bring all employees together and achieve newer goals, on the field! To know more about some exciting corporate sports events do connect with us. </p>
                            </div>
                        </div>

                        <div className="media corporateArea">
                            <div className="media-left">
                                <a href="#">
                                    <img className="media-object" src="../assets/images/icon/winner-champion-trophy.png" alt="Tournaments" title="Tournaments" />
                                </a>
                            </div>
                            <div className="media-body">
                                <h4 className="media-heading"> Tournaments </h4>
                                <p> At the corporate level we have conceptualized various tournaments in various sports like cricket, football, basketball and swimming. Such tournaments are highly competitive and also become the prefect bonding session. These tournaments are focussed at bring employees of various companies or the same company together and compete with each other on a different level. Such events help the company and the employees in many ways </p>
                            </div>
                        </div>

                        <div className="media corporateArea">
                            <div className="media-left">
                                <a href="#">
                                    <img className="media-object" src="../assets/images/icon/company.png" alt="Benefits for Company" title="Benefits for Company" />
                                </a>
                            </div>
                            <div className="media-body">
                                <h4 className="media-heading"> Benefits for Company </h4>
                                <ul className="commanListstyle">
                                    <li> Branding </li>
                                    <li> Image Building </li>
                                    <li> Higher Productivity of Employees </li>
                                    <li> Happy Work Environment  </li>
                                </ul>
                            </div>
                        </div>

                        <div className="media corporateArea">
                            <div className="media-left">
                                <a href="#">
                                    <img className="media-object" src="../assets/images/icon/company-employee.png" alt="Benefits for Employees" title="Benefits for Employees" />
                                </a>
                            </div>
                            <div className="media-body">
                                <h4 className="media-heading"> Benefits for Employees </h4>
                                <ul className="commanListstyle">
                                    <li> Better Health </li>
                                    <li> Perfect Venue for Socializing </li>
                                    <li> Reduced Health Problems </li>
                                    <li> Effective Relationship Building </li>
                                </ul>
                            </div>
                        </div>

                        <div className="media corporateArea">
                            <div className="media-left">
                                <a href="#">
                                    <img className="media-object" src="../assets/images/icon/open-book.png" alt="Bookings" title="Bookings" />
                                </a>
                            </div>
                            <div className="media-body">
                                <h4 className="media-heading"> Bookings </h4>
                                <p> We offer great deals and packages for corporates. Bookings for the cricket ground, football turf, basketball court & swimming pool are now open. To get an attractive deal for your company contact us at: <a href="mailto:nimhan777@gmail.com"> nimhan777@gmail.com </a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Corporate;
