import React from 'react';
import { Link } from 'react-router-dom';

function ContactTagline() {
    return (
        <section id="contactTagline" className="pad-30">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <h6 className="hightlightHead"> We'd love to hear from you !!! </h6>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <div className="contactBtn">
                            <Link to="/contact-us" className="effect effect1"> Contact Us </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactTagline;
