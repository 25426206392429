import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
const InnerPageBanner = () => (
    <section className="inner-page-banner-area news">
        <div className="container">
            <div className="row bannertext">
                <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="banner-caption">
                        <h1>About Us</h1>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-6 breadcrumbs-area">
                    <ol className="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li>About Us</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
);

const AboutUsContent = () => (
    <div className="container pad-40 wow fadeIn" id="InternalPageArea" style={{ visibility: 'visible', animationName: 'fadeIn' }}>
        <div className="row">
            <div className="col-sm-12 col-xs-12">
                <p>
                    Established in the year 2007, Sanjay Sports Academy is one of Pune’s leading sports clubs with provision for a variety of outdoor sports facilities namely: cricket, basketball, football, and swimming.

                    Situated at Pashan, Pune, the academy has been built on a ____ acre area and has all the latest state of the art sporting equipment’s. With excellent amenities and other facilities in place, we are proud to say that Sanjay Sports Academy is the finest sports training center in the entire Pashan area. The academy believes in providing quality training at affordable costs.

                    Having said this, the academy focuses on constant up-gradation and enhancement of infrastructure on a regular basis without making the services exorbitant. The renowned coaches and trainers on the academy’s panel make the academy a trusted name amongst athletes and budding sportsmen. Founded by Mr. Chintamani Nimhan, Sanjay Sports Academy has been actively training and grooming aspiring sportsmen to achieve a higher level of excellence.
                </p>
            </div>
        </div>

        {/* vision mission block */}
        <div className="row">
            <div className="col-sm-12 col-xs-12">
                <h3>Vision & Mission</h3>
            </div>
        </div>
        <div className="row visionmissnOuter">
            <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="media">
                    <div className="media-left">
                        <a href="#">
                            {/* Add your image here */}
                        </a>
                    </div>
                    <div className="media-body grn">
                        <div className="vision">
                            <a href="#">Vision</a>
                        </div>
                        <p>
                            To identify, nurture and sharpen inside an individual for sports while molding the performance for a winning streak and make winning a habit.
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="media missionBox">
                    <div className="media-left">
                        <a href="#">
                            {/* Add your image here */}
                        </a>
                    </div>
                    <div className="media-body">
                        <div className="mission">
                            <a href="#">Mission</a>
                        </div>
                        <p className="misntext">
                            To be the first choice of budding sportsmen and ensure a growing presence through setting leadership standards, performance, and customer satisfaction in the field of sports.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const DirectorSection = () => (
    <div className="container-fluid Directorareaouter pad-30" id="InternalPageArea">
        <div className="container">
            <div className="row text-center">
                <div className="col-sm-12 col-xs-12">
                    <div className="promoterOuter">
                        <h3>Our Promoters</h3>
                        <div className="imgWrapper">
                            <img src="../../assets/images/director-speak.jpg" alt="Mr. Chintamani Nimhan" title="Mr. Chintamani Nimhan" />
                        </div>
                        <p>
                            Mr. Chintamani Nimhan,
                            As a sports enthusiast, Mr. Chintamani Nimhan understood the need for efficient sports infrastructure in the city, and that’s when Sanjay Sports Academy was started. The academy became functional in the year 2007, and since then, there was no looking back.
                            His passion for sports and business expertise is a unique blend that is taking the academy to greater heights. He believes that the customer comes first, and that’s why the academy provides premium services at an affordable cost.
                            Mr. Chintamani is known for innovation, and this quality of his is making the academy successful every day. His ultimate goal is to make Sanjay Sports Academy one of Pune’s finest centers for sports and fitness.
                            To connect with Mr. Chintamani write to him at ____________
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const TeamMembers = () => (
    <div className="container pad-40" id="InternalPageArea">
        <div className="row">
            <div className="col-sm-12 col-md-12 col-xs-12">
                <h3>Team Members</h3>
            </div>
        </div>
        <div className="row">
            {/* You can map through your team members and create individual components for each */}
            <div className="col-sm-6 col-md-3 col-xs-12">
                <div className="teamwrapper">
                    <img src="../../assets/images/dummy-profile-01.jpg" alt="Mr. Lorem Ipsum" className="image" />
                    <div className="overlay">
                        <div className="text">
                            Mr. Lorem Ipsum
                            <span>Lorem Ipsum is simply dummy text</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-sm-6 col-md-3 col-xs-12">
                <div className="teamwrapper">
                    <img src="../../assets/images/dummy-profile-01.jpg" alt="Mr. Lorem Ipsum" className="image" />
                    <div className="overlay">
                        <div className="text">
                            Mr. Lorem Ipsum
                            <span>Lorem Ipsum is simply dummy text</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-6 col-md-3 col-xs-12">
                <div className="teamwrapper">
                    <img src="../../assets/images/dummy-profile-01.jpg" alt="Mr. Lorem Ipsum" className="image" />
                    <div className="overlay">
                        <div className="text">
                            Mr. Lorem Ipsum
                            <span>Lorem Ipsum is simply dummy text</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-6 col-md-3 col-xs-12">
                <div className="teamwrapper">
                    <img src="../../assets/images/dummy-profile-01.jpg" alt="Mr. Lorem Ipsum" className="image" />
                    <div className="overlay">
                        <div className="text">
                            Mr. Lorem Ipsum
                            <span>Lorem Ipsum is simply dummy text</span>
                        </div>
                    </div>
                </div>
            </div>
            {/* Repeat this structure for each team member */}
        </div>
    </div>
);


function AboutUs() {
    const pageTitle = ':: About Us | Sanjay Sports Academy ::';
    useEffect(() => {
        document.title = pageTitle;
    }, [pageTitle]);
    return (
        <div>
            <Header />
            <InnerPageBanner />
            <AboutUsContent />
            <DirectorSection />
            {/* <TeamMembers /> */}
            <Footer />
        </div>
    );
}

export default AboutUs;
