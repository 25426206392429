import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'font-awesome/css/font-awesome.min.css';
import '../assets/css/animate.css';
import '../assets/css/style.css';
import '../index.css';
import { useState, useEffect } from 'react'; // Import the useEffect hook
import { Link, useLocation } from 'react-router-dom'; // Import useLocation

function Header() {
    const [isOpen, setIsOpen] = React.useState(true);
    const location = useLocation(); // Use useLocation to get the current location
    const [loading, setLoading] = useState(false); // State for tracking loading

    const toggleNav = () => {
        setIsOpen(!isOpen);
    };

    const isLinkActive = (path) => {
        return location.pathname === path;
    };

    useEffect(() => {
        // Simulate loading effect
        const loadingTimer = setTimeout(() => {
            setLoading(false);
        }, 1500); // Adjust the timeout as needed

        // Clean up the timer when the component unmounts
        return () => clearTimeout(loadingTimer);
    }, []);

    return (
        <>
            <section className="topnav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 topLinksWarpIn">
                            <div className="toplinkLft">
                                <ul className="nav navbar-nav navbar-right menu-right">
                                    <ul className="nav navbar-nav contactsec">
                                        <li><Link to='callto:7028006577' className='callToPhone'><i className="fa fa-phone" aria-hidden="true"></i> 7028006577</Link></li>
                                    </ul>
                                    <ul className="Socialicons">
                                        <li className="facebook">
                                            <Link to="https://www.facebook.com/sanjayfutsal/" title="Facebook" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></Link>
                                        </li>
                                        <li className="twitter">
                                            <Link to="https://instagram.com/sanjaysportsacademy?igshid=MzRlODBiNWFlZA== " title="Instagram"><i className="fa fa-instagram" aria-hidden="true"></i></Link>
                                        </li>
                                        <li className="youtube">
                                            <Link href=" " title="Youtube"><i className="fa fa-youtube-play" aria-hidden="true"></i></Link>
                                        </li>
                                    </ul>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <nav className="navbar navbar-default">
                <div className="container">
                    <div className="navbar-header">
                        <button
                            type="button"
                            className="navbar-toggle collapsed"
                            data-toggle="collapse"
                            data-target="#bs-example-navbar-collapse-1"
                            aria-expanded="false"
                        >
                            {/* ... */}
                        </button>
                        <Link to="/" className="navbar-brand">
                            <img
                                src="../assets/images/sanjay-sports-logo.png"
                                alt="Sanjay Sports Academy"
                                title="Sanjay Sports Academy"
                            />
                        </Link>
                    </div>
                    <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                        <ul className="nav navbar-nav navbar-right menu-top">
                            <li className={isLinkActive('/') ? 'active' : ''}>
                                <Link to="/">Home</Link>
                            </li>
                            <li className={isLinkActive('/about-us') ? 'active' : ''}>
                                <Link to="/about-us">About Us</Link>
                            </li>
                            <li className={isLinkActive('/sports-facilities') ? 'active' : ''}>
                                <Link to="/sports-facilities">Sports Facilities</Link>
                            </li>
                            <li className={isLinkActive('/news-and-events') ? 'active' : ''}>
                                <Link to="/news-and-events">Sanjay Sports Events</Link>
                            </li>
                            <li className={isLinkActive('/corporates') ? 'active' : ''}>
                                <Link to="/corporates">Corporates</Link>
                            </li>
                            <li className={isLinkActive('/training-coachings') ? 'active' : ''}>
                                <Link to="/training-coachings">Training & Coachings</Link>
                            </li>
                            <li className={isLinkActive('/contact-us') ? 'active' : ''}>
                                <Link to="/contact-us">Contact Us</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Header;
