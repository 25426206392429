import React from 'react';
import { Link } from 'react-router-dom';

function BoxingSection() {
    return (
        <section id="boxingArea" className="pad-30">
            <div className="container-fluid">
                <div className="row text-center">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <h1> Boxing & Kick Boxing Academy (Since-2020) </h1>
                        <p> We offer below services, for more information contact our Coach Mr. Akash Pirganwar - 8975909602. </p>
                    </div>
                </div>
                <div className="row eventContents" id="counter">
                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="eventsArea">
                            <img src="../../assets/images/boxing/boxing.jpg" alt="boxing" title="boxing" className="span3 wow fadeInUp animated" data-wow-delay="0.2s" style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp' }} />
                            <h3> Boxing </h3>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="eventsArea">
                            <img src="../../assets/images/boxing/kick-boxing.jpg" alt="Kick Boxing" title="Kick Boxing" className="span3 wow fadeInUp animated" data-wow-delay="0.4s" style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp' }} />
                            <h3> Kick Boxing </h3>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="eventsArea">
                            <img src="../../assets/images/boxing/self-defence.jpg" alt="Self Defence" title="Self Defence" className="span3 wow fadeInUp animated" data-wow-delay="0.6s" style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp' }} />
                            <h3> Self Defence </h3>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="eventsArea">
                            <img src="../../assets/images/boxing/fitness.jpg" alt="Fitness" title="Fitness" className="span3 wow fadeInUp animated" data-wow-delay="0.8s" style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp' }} />
                            <h3> Fitness </h3>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                        <Link to="shivohum-academy" className="effect effect1">
                            Read More
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BoxingSection;
