import React from 'react';
import { Link } from 'react-router-dom';

function FutsalSection() {
    return (
        <section id="futsalArea" className="wow fadeIn pad-30">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="futsallogoOuter">
                            <Link to="/sanjay-futsal" target="_blank" rel="noopener noreferrer">
                                <div className="futsallogoinner">
                                    <img src="../../assets/images/futsal-home-logo.jpeg" alt="Sanjay Futsal Logo" className='futal-logo' />
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                        <div className="futsaltext">
                            <h1> SANJAY FUTSAL </h1>
                            <p>
                                Futsal (Portuguese Pronunciation) is a variant of football that is played on a smaller field and mainly played indoors. It is the official five-a-side indoor soccer game and the only indoor soccer recognized by FIFA (Federation of Internationale Football Association). Futsal is the game of 5 vs 5 players, including the goalkeepers, and is played on a small field roughly the size of a basketball court. The game is played with the touchline boundaries and does not require the use of dasher boards.
                            </p>
                            <p className="futsalBtns">
                                <Link to="/sanjay-futsal" className="effect effect1" target="_blank" rel="noopener noreferrer"> Read More </Link>
                                <a href="https://www.facebook.com/sanjayfutsal/" className="effect effect1 fb" target="_blank" rel="noopener noreferrer">
                                    <i className="fa fa-thumbs-up"></i> Find Us on Facebook
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FutsalSection;
